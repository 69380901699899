@import "../../styles//styles.scss";
@import "./homeStyles";

.Wrapp {
  position: relative;
  background-color: #fff;
}

.ContainerGrid {
  max-width: 1600px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-rows: repeat(12, 1fr); */
  min-height: 850px;
  gap: 1rem;
  position: relative;
  overflow: hidden;
  padding: 0 2rem;

  @media (max-width: 768px) {
    grid-template-rows: 90vh;
  }
}

.ContainerGridClaim {
  max-width: 1600px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-rows: repeat(12, 1fr); */
  grid-template-rows: 110vh;
  gap: 1rem;
  position: relative;
  overflow: hidden;
  padding: 0 2rem;

  @media (max-width: 768px) {
    grid-template-rows: 90vh;
    height: auto;
  }
  @media (max-width: 592px) {
    grid-template-rows: 70vh;
    height: auto;
  }
}

.ImageRoom {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.ContainerGridBottom {
  overflow: hidden;
  margin: auto;
}

.BottomText {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 65%;
  left: 0;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 100%;
    align-items: flex-end;
  }
}
.BottomTextSpan {
  font-family: $font15;
  font-weight: bold;
  font-size: 74px;
  line-height: 55px;
  letter-spacing: 2%;
  color: #fff;
  text-transform: uppercase;
  overflow: hidden;
  padding: 20px 0;

  @media (max-width: 768px) {
    font-family: $font15;
    font-weight: 400;
    font-size: 38px;
    line-height: 38px;
    letter-spacing: 2%;
    width: 100%;
    margin-bottom: 6rem;
  }
}

.BottomTextSpanOutlined {
  font-family: $font15;
  font-weight: bold;
  font-size: 74px;
  line-height: 55px;
  letter-spacing: 2%;
  color: transparent;
  text-transform: uppercase;
  -webkit-text-stroke-width: 0.4px;
  -webkit-text-stroke-color: #fff;

  @media (max-width: 768px) {
    font-family: $font15;
    font-weight: 400;
    font-size: 38px;
    line-height: 38px;
    letter-spacing: 2%;
    width: 100%;
  }
}

.Carrousel {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;

  & li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.CarrouselLogos {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
