@import 'styles.scss';

    .no-results {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 1;
        gap: 16px;
        margin-top: 170px;
    
        span {
            font-size: 52px;
        }
    
        h2 {
            font-size: 36px;
            font-weight: bold;
            color: #181D27!important;
        }
    
        p {
            font-size: 20px;
            color: #535862;
        }
    
        .shop-button {
            background-color: #000;
            color: #fff;
            padding: 10px 20px;
            border-radius: 8px;
            text-decoration: none;
            font-size: 16px;
            font-weight: bold;
        }
    
          .shop-button:hover {
            background-color: #333;
        }
    }
