@import "../../styles//styles.scss";
@import "./homeStyles";

.Container {
  @include grid-container(repeat(12, 1fr), null);
  min-height: 800px;
  position: relative;
  overflow: hidden;
  background-color: black;
}

.Image {
  @include grid-start-end(1, 13, 1, 13);
  @include media-query($max-width-desktop-large) {
    display: grid !important;
    @include grid-start-end(1, 13, 1, 13);
  }
  @include media-query($max-width-desktop) {
    display: grid !important;
    @include grid-start-end(1, 13, 1, 13);
  }
  @include media-query($max-width-tablet) {
    display: grid !important;
    @include grid-start-end(1, 7, 1, 13);
  }
  @include media-query($max-width-mobile) {
    display: grid !important;
    @include grid-start-end(1, 3, 1, 13);
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include media-query($max-width-tablet) {
      object-position: 70% 50%;
    }
    @include media-query($max-width-mobile) {
      object-position: 73% 50%;
    }
  }
}

.Claim {
  & span {
    font-size: 5rem;
    line-height: calc(5rem * 0.8);
    color: #fff;
    font-family: $primaryFont;
    font-weight: bold;
    text-transform: uppercase;

    @include media-query($max-width-desktop) {
      font-size: 4rem;
      line-height: calc(4rem * 0.8);
    }

    @include media-query($max-width-tablet) {
      font-size: 3.8rem;
      line-height: calc(3.8rem * 0.8);
    }

    @include media-query($max-width-mobile) {
      font-size: 2.3rem;
      line-height: calc(2.3rem * 0.8);
    }
  }

  @include grid-start-end(1, 10, 5, 13);
  padding: 2rem;

  @include media-query($max-width-desktop-large) {
    display: grid !important;
    @include grid-start-end(1, 9, 4, 13);
  }
  @include media-query($max-width-desktop) {
    display: grid !important;
    @include grid-start-end(1, 7, 4, 13);
  }
  @include media-query($max-width-tablet) {
    display: grid !important;
    @include grid-start-end(1, 7, 7, 13);
  }
  @include media-query($max-width-mobile) {
    display: grid !important;
    @include grid-start-end(1, 3, 8, 12);
    padding: 1rem;
  }

  .BottomTextSpan {
    display: flex;
    flex-direction: column;
    gap: 0;
    flex-wrap: wrap;
  }

  .BottomTextSpanOutlined {
    -webkit-text-stroke-width: 0.56px;
    -webkit-text-stroke-color: #fff;
    font-family: $primaryFont;
    color: transparent;
  }
}
.carousel {

  @include grid-start-end(1, 13, 11, 13);
  @include media-query($max-width-desktop-large) {
    display: grid !important;
    @include grid-start-end(1, 13, 11, 13);
  }
  @include media-query($max-width-desktop) {
    display: grid !important;
    @include grid-start-end(1, 13, 11, 13);
  }
  @include media-query($max-width-tablet) {
    display: grid !important;
    @include grid-start-end(1, 7, 11, 13);
  }
  @include media-query($max-width-mobile) {
    display: grid !important;
    @include grid-start-end(1, 3, 11, 13);
  }

  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: transparent;
  align-self: end;
  height: 94px;

  .line {
    position: absolute;
    display: flex;
    width: max-content;
    will-change: transform;
    div {
      width: 220px;
      text-align:center;
      padding-bottom: 20px;
    }
    @media (max-width:991px) {
      div {
        width: 150px;
      }
    }
  }
}
.carouselBlack {
  background: white;
  height: 75px !important;
  div {
    padding-bottom: 0 !important;
  }
  img {
    filter: invert(1);
  }
}