@import "styles.scss";

#revelated {
  .wrapper-revelated {
    min-height: 100vh;
    background: linear-gradient(225deg, #485362 0%, #151a21 88.54%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: aliceblue;

    .wrapper-video-reveal {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: stretch;
      @media (max-width: 992px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      &.show-video-reveal {
        display: flex;
        align-items: center;
        justify-content: center;

        & .mona {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @media (max-width: 992px) {
            padding: 0;
          }
        }
      }

      &.on-artwork {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        height: 100vh;

        @media (max-width: 992px) {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: auto;
        }
      }

      .mona {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.show-mona {
          @media (max-width: 992px) {
            padding: 0;
            width: 100%;
          }
          img {
            object-position: center;
            object-fit: contain;
            height: 100%;
            @media (max-width: 992px) {
              height: auto;
              width: 100%;
            }
            @media (max-width: 1200px) {
              width: 100%;
            }
          }
        }

        &.start-btn {
          img {
            object-position: center;
            object-fit: contain;
            height: 100%;
          }
        }

        .play-btn {
          width: 100%;
          @media (max-width: 992px) {
            width: 80%;
          }
        }
      }

      .play-title {
        color: #fff;
        text-align: center;
        font-family: $primaryFont;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 123.077% */
      }

      span {
        color: #fff;
        text-align: center;
        font-family: $secondaryFont;
        font-size: 14.64px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 163.932% */
      }

      /* img {
        object-position: center;
        object-fit: contain;
        height: 100%;
      } */

      video {
        height: 100vh;
        object-fit: contain;
        object-position: center;
      }

      .grid-btn-reveal {
        padding: $dimenM;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        width: 100%;
        height: 100vh;
        @media (max-width: 992px) {
          height: auto;
        }

        .title {
          color: #fff;
          text-align: center;
          font-family: $primaryFont;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 29px; /* 131.818% */
          margin-bottom: $dimenM;
        }

        .artwork-number {
          color: #fff;
          font-family: $primaryFont;
          font-size: 48px;
          font-style: normal;
          font-weight: 400;
          line-height: 45px; /* 93.75% */
          text-align: center;
          @media (max-width: 992px) {
            font-size: 32px;
          }
        }

        .box-reveal-artwork {
          border-radius: 50px;
          background: #161b22;
          padding: $dimenS;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: $dimenXS;
          @media (max-width: 992px) {
            display: none;
          }
        }
      }
    }
    .btn-payment-method {
      display: flex;
      height: 48px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex-shrink: 0;
      border-radius: 6px;
      background: #fff;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
    }
  }
}

#users-revelated {
  background: linear-gradient(225deg, #485362 0%, #151a21 88.54%);
  .wrapper-users-list {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: aliceblue;

    .wrapper-users-list-grid {
      width: 100%;
      height: 100%;
      position: relative;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;

      .grid-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .col-list-1 {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-top: $dimenXL;
        padding-bottom: $dimenXL;
      }

      .container-list {
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .title {
          color: #fff;
          text-align: center;
          font-family: $secondaryFont;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .text {
          color: #fff;
          text-align: center;
          font-family: $secondaryFont;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px; /* 150% */
        }
      }

      ul {
        padding: $dimenM;
        margin-top: $dimenM;
        max-height: 555px;
        overflow-y: scroll;
      }

      .ul-list {
        background-color: white;
        min-width: 400px;
        color: black;
        padding: 16px 15px;
        border-radius: 6px;
        list-style: none;
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.li-selected {
          border: 1px solid #aed3ff;
          background: #5c7a99;
          color: white;
          & span:nth-child(2) {
            color: #c6c6c6;
          }
        }
      }
    }
  }
}

/* Estilos para pantallas pequeñas (por ejemplo, iPad Mini) */
/* @media (max-width: 768px) {
} */

/* Estilos para pantallas medianas (por ejemplo, iPad Air) */
/* @media (min-width: 769px) and (max-width: 1024px) {
} */

/* Estilos para pantallas grandes (por ejemplo, iPad Pro) */
/* @media (min-width: 1025px) {

} */

/* cambios css para modo vertical y horizontal */
/* @media only screen
and (min-width: 768px)
and (max-width: 1024px)
{
} */
/* cambios css solo para modo vertical */
/* @media only screen
and (min-width: 768px)
and (max-width: 1024px)
and (orientation: portrait)
{
} */

/* cambios css solo para modo horizontal */
/* @media only screen
and (min-width: 768px)
and (max-width: 1024px)
and (orientation: landscape)
{
} */
