@import "./styles.scss";

.headerVideo {
  width: 100%;
  height: 100vh;
  background: #222;
}

.parallax-container {

  margin-bottom: 100px;
  .parallax-scroller {
    width: 100%;
  }
  .parallax-scroller::-webkit-scrollbar {
    display: none;
  }
  .parallax-scroller {
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
  }
  .parallax-scroller {
    -ms-overflow-style: none;
  }
  .parallax-item {
    will-change: transform;
    text-align:center;
    position: absolute;
    max-width: 90%;
  }
  h1,
  h2 {
    font-family: $primaryFont;
  }
  p {
    font-family: $secondaryFont;
    font-size: 24px;
  }
  .title-one {
    z-index: 2;
    width: 810px;
    max-width: 100%;
    text-align: center;
    h1 {
      font-size: 60px;
    }
    p {
      margin: 0 auto;
      width: 570px;
      max-width: 100%;
    }
  }
  .title-two,
  .title-three {
    text-align: left;
    z-index: 2;
    width: 50%;
    h2 {
      font-size: 60px;
    }
    @media (max-width: 991px) {
      h2 {
        font-size: 40px;
      }
    }
  }
  .title-three {
    right: 0;
  }
  @media (max-width: 991px) {
    .title-two {
      margin-top: 1000px;
    }
    .title-three {
      margin-top: 2200px;
    }
  }
  img {
    cursor: pointer;
    opacity: 30%;
    filter:blur(4px);
    z-index: 1;
  }
  img:hover {
    opacity: 60%;
    filter: blur(0);
  }
  .one {
    width: 264px;
    left: 10%;
  }
  .two {
    width: 393px;
    left: 35%;
  }
  .three {
    width: 333px;
    right: 5%;
  }
  .four {
    width: 618px;
    left: 0%;
  }
  .five {
    width: 410px;
    right: 10%;
  }
  .six {
    width:334px;
    left: 5%;
  }
  .seven {
    width: 522px;
    right: 20%;
  }
  .eight {
    width: 421px;
    left: 0%;
  }
  .nine {
    width: 474px;
    right: 0%;
  }
  .ten {
    width:296px;
    left: 0%;
  }
  .eleven {
    width: 264px;
    left: 35%;
  }
  .twelve {
    width: 395px;
    right: 5%;
  }
  @media (max-width: 1399px) {
    .three {
      width: 300px;
      right: 0%;
    }
    .five {
      width: 360px;
      right: 5%;
    }
    .seven {
      width: 480px;
      right: 15%;
    }
  }
  @media (max-width: 1199px) {
    .eight,
    .two {
      margin-top: 100px;
    }
    .seven,
    .five,
    .three {
      margin-top: 250px;
    }
  }
  @media (max-width: 991px) {
    padding: 0px 10px;
    height: 7300px;
    p {
      font-size: 18px;
    }
    .title-one {
      h1 {
        font-size: 50px;
      }
    }
    .title-two,
    .title-three {
      width: 100%;
    }
    img.parallax-item {
      left: 0 !important;
      right: 0 !important;
      margin-left: auto;
      margin-right: auto;
    }
    .two {
      margin-top: 405px;
    }
    .three {
      margin-top: 495px;
    }
    .four {
      margin-top: 525px;
    }
    .five {
      margin-top: 725px;
    }
    .six {
      margin-top: 705px;
    }
    .seven {
      margin-top: 755px;
    }
    .eight {
      margin-top: 775px;
    }
    .nine {
      margin-top: 1025px;
    }
    .ten {
      margin-top: 1475px;
    }
    .eleven {
      margin-top: 1175px;
    }
    .twelve {
      margin-top: 1975px;
    }
  }
}

.algorithm {
  min-height: 100vh;
  width: 100%;
  background: #4CB3C4;
  color: white;

  p {
    font-family: $secondaryFont;
    font-size: 24px;
    line-height: 32px;
  }
  p.title {
    font-family: $primaryFont;
    font-size: 60px;
    line-height: 72px;
  }
  @media (max-width: 991px) {
    p.title {
      font-size: 40px;
      line-height: 45px;
    }
    p {
      padding: 20px 0;
    }
    .image {
      margin-bottom: 15px;
    }
  }
  img {
    width: 580px;
    max-width: 100%;
  }
}
.picture {
  min-height: 100vh;
  width: 100%;
  .box {
    display: inline-block;
    position: relative;
    img {
      width: 523px;
      max-width: 100%;
      box-shadow: 0px 0px 20px gray;
    }

    .bubble-container {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      .bubble {
        font-family: $secondaryFont;
        font-size: 14px;
        border: 1px solid #D5D7DA;
        padding: 16px 12px;
        border-radius: 12px;
        width: 262px;
      }
      .line {
        height: 1px;
        border:1px solid #E9EAEB;
        width: 100px;
      }
      .circle {
        width: 18px;
        height: 18px;
        border: 1px solid white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: white;
          display: block;
        }
      }
    }
    @media (max-width: 991px) {
      img {
        width: 310px;
      }
      .carousel-artPrint {
        margin-top: -60px;
        margin-bottom: 30px;
        height: 256px;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
          display: block;
          margin-top: 100px;
          background-image: url('../assets/arrow-carousel-gray.svg');
      }
      .bubble-container {
        transform: translateX(0);
        position: relative;
        padding-top: 117px;
        .line {
          width: 2px;
          height: 100px;
          position:absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          z-index: 9;
        }
        .circle {
          position:absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .one {
        .line {
          top: 17px;
        }
        .circle {
          top:0;
        }
      }
      .two {
        .line {
          top: 56px;
          height: 62px;
        }
        .circle {
          top: 42px;
        }
      }
      .three {
        .line {
          top: 45px;
          height: 76px;
        }
        .circle {
          top: 30px;
        }
      }
      .four {
        .line {
          top: 42px;
          height: 76px;
          transform: translateX(120px);
        }
        .circle {
          top: 28px;
          transform: translateX(120px);
        }
      }
    }
    .bubble-top-left {
      top: 20%;
      left: 0%;
      transform: translateX(-300px);
    }
    .bubble-bottom-left {
      top: 80%;
      left: 0%;
      transform: translateX(-357px);
    }
    .bubble-top-right {
      top: 0%;
      right:0%;
      transform: translateX(280px)
    }
    .bubble-bottom-right {
      bottom: 10%;
      right: 0%;
      transform: translateX(325px);
      .line {
        width: 55px;
      }
    }
  }
}

.more-info {
  border-top: 1px solid #D5D7DA;
  .random-items {
    div {
      width: 19%;
    }
    @media (max-width: 991px) {
      div {
        width: 100%;
      }
    }
    img {
      width: 100%;
      max-width: 100%;
    }
    .message {
      position:absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      color: white;
      font-family: $primaryFont;
      font-size: 40px;
      line-height: 50px;
      display: flex;
      align-items: flex-end;
      padding-left: 12px;
      text-shadow: 0px 0px 5px gray;
    }
    @media (max-width: 991px) {
      .message {
        font-size: 50px;
        line-height: 55px;
      }
    }
  }
  .title {
    font-family: $secondaryFont;
    color: #848484;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 3rem;
  }
  a {
    font-family: $primaryFont;
    font-size: 145px;
    color: black;
    display: block;
    text-decoration: none;
    line-height: 145px;
    cursor:pointer;
  }
  a.desk:hover {
    color:#4BB3C4;
  }
  a.certificate:hover {
    color: #EEADD7
  }
  a.faqs:hover {
    color: #F3C020;
  }
  a.news:hover {
    color: #9b8fcc;
  }
  @media (max-width: 991px) {
    .title {
      margin-bottom: 1rem;
    }
    a {
      font-size: 50px;
      line-height: 65px;
    }
  }
}

.artworkLandingModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  .box {
    background: black;
    padding: 32px;
    border-radius: 16px;
    width: 768px;
    max-width: 95%;
    .top {
      text-align:right;
      button {
        background: white;
        border: 0;
        border-radius: 50%;
        width: 44px;
        height: 44px;
        color: black;
        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 24px
      }
    }
    .center {
      margin-top: 12px;
      width: 704px;
      max-width: 95%;
      border-radius: 8px;
      background: white;
      text-align: center;
      img {
        border-radius: 8px;
        width: 100%;
      }
    }
    .bottom {
      color: white;
      padding-top: 12px;
      p {
        font-family: $secondaryFont;
        font-size: 16px;
      }
      p.title {
        font-size: 20px;
        font-weight: bold;
      }
      .buttons {
        text-align: right;
        button {
          background: white;
          color: black;
          font-family: $primaryFont;
          font-size: 16px;
          border-radius: 8px;
          padding: 10px 18px;
        }
      }
    }
  }
}