@import "../../styles//styles.scss";
@import "./homeStyles";

.Container {
  @include grid-container(repeat(12, 1fr), null);
  min-height: 800px;
  position: relative;
  overflow-x: scroll;
  overflow: auto;
  scrollbar-width: none;

  &.noScroll {
    overflow-x: hidden;
    overflow: hidden;
    scrollbar-width: none;
  }

  & span:nth-child(1) {
    position: relative;
    @include grid-start-end(1, 13, 1, 13);

    @include media-query($max-width-desktop-large) {
      display: grid !important;
      @include grid-start-end(1, 13, 1, 13);
    }
    @include media-query($max-width-desktop) {
      display: grid !important;
      @include grid-start-end(1, 13, 1, 13);
    }
    @include media-query($max-width-tablet) {
      display: grid !important;
      @include grid-start-end(1, 7, 1, 13);
    }
    @include media-query($max-width-mobile) {
      display: grid !important;
      @include grid-start-end(1, 3, 1, 13);
    }
  }

  & .ContainerImage {
    overflow: scroll;
    min-width: 100%;
    min-height: 100%;
  }

  & .ImageRoom {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;

    @include media-query($max-width-desktop) {
      width: auto;
      height: 100%;
    }
  }
}

.point1 {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & svg:nth-child(1) {
    position: absolute;
    top: 30%;
    left: 45%;

    @include media-query($max-width-desktop) {
      top: 28%;
      left: 59%;
    }

    @include media-query($max-width-tablet) {
      top: 28%;
      left: 90%;
    }

    @include media-query($max-width-mobile) {
      top: 28%;
      left: 130%;
    }
  }

  & svg:nth-child(2) {
    position: absolute;
    top: 74%;
    left: 50%;

    @include media-query($max-width-desktop-large) {
      top: 74%;
      left: 58%;
    }

    @include media-query($max-width-desktop) {
      top: 74%;
      left: 85%;
    }

    @include media-query($max-width-tablet) {
      top: 74%;
      left: 129%;
    }

    @include media-query($max-width-mobile) {
      top: 74%;
      left: 174%;
    }
  }
}
