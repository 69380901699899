@import "styles.scss";

.checkougPage {
  .form-head {
    border-bottom: 1px solid #EAEAEA;
    margin-bottom: 30px;
    .title {
      font-family: $primaryFont;
      font-size: 24px;
      font-weight: 400;
    }
    .description {
      font-family: $secondaryFont;
      font-size: 16px;
      font-weight: 400;
    }
  }
  label {
    font-family: $secondaryFont;
    font-size: 14px;
    font-weight: 700;
  }
  .form-check {
    margin-bottom: 20px;
    label {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .step0Form {
    .form-check-input:checked {
      background-color: #ffffff !important;
      border-color: #4BB3C4 !important;
      box-shadow: none;
      position: relative;
    }
    .form-check-input:checked::after {
      content: '✔';
      font-size: 16px;
      color: #4BB3C4;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .form-check-input {
      background-color: #ffffff !important;
      border-color: #ced4da !important;
      appearance: none;
      width: 1.2em;
      height: 1.2em;
      border-radius: 3px;
      display: inline-block;
      position: relative;
      cursor: pointer;
    }
  }

  .breadcrumbs {
    a {
      color: #A4A7AE;
      font-size: 14px;
      text-decoration: none;
      margin: 0 12px;
    }
    a:first {
      margin-left: 0;
    }
    svg {
      transform: rotate(180deg);
    }
    .active {
      color:#4BB3C4;
      font-size: 14px;
      margin-left: 12px;
    }
  }
  .progressBar {
    .circle {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 2px solid #D5D7DA;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: block;
        border-radius: 50%;
        width: 16px;
        height: 16px;
      }
    }
    .circle.active {
      border: 2px solid #4BB3C5;
      span {
        background-color: #4BB3C5;
      }
    }
    .circle.next {
      border: 2px solid #A4A7AE;
      span {
        background-color: #A4A7AE;
      }
    }
    .circle.completed {
      background-color: #4BB3C5;
      border-color: #4BB3C5;
      background-image: url('../assets/checkout/Icon.png');
      background-repeat: no-repeat;
      background-position: center center;
    }
    .line {
      width:63px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        width: 100%;
        border: 0;
        height: 2px;
        background-color: #D5D7DA;
      }
      span.active {
        background-color: #4BB3C5;
      }
    }
  }

  .paymentMethod {
    .form-check {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #E3E3E3;
      padding-bottom: 24px;
    }
    .form-check-input {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 25px;
      height: 25px;
      border: 2px solid #E0E0E0;
      border-radius: 50%;
      background-color: white;
      position: relative;
      cursor: pointer;
      transition: border-color 0.3s ease-in-out;
      margin-right: 24px;
    }
    .form-check-input:checked {
        border-color: #4BB3C5;
    }
    .form-check-input:checked::before {
        content: "";
        width: 13px;
        height: 13px;
        background-color: #4BB3C5;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .form-check-input:focus {
        box-shadow: none;
        outline: none;
    }
  }
  .summary {
    font-family: $secondaryFont;
    font-size: 16px;
    border-bottom: 1px solid #EAEAEA;
    margin-top: 24px;
    padding-bottom: 24px;
    @media (max-width: 991px) {
      .edit-button {
        margin-top:12px;
      }
    }
    p {
      margin-bottom:0;
    }
    .title {
      font-family: $primaryFont;
      font-size: 24px;
      margin-bottom: 12px;
    }
    button {
      border: 1px solid #D5D7DA;
      border-radius: 8px;
      font-size: 16px;
      font-family: $primaryFont;
      background: white;
      padding: 10px 18px;
      color: #414651;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-right: 6px;
      }
    }
  }
}
