* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto";
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  /*overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.eff-title {
  overflow: hidden;
  font-family: "FranklinGothic", sans-serif;
  font-weight: bold;
}
.eff-title .letter {
  font-weight: bold;
  font-family: "FranklinGothic", sans-serif;
  display: inline-block;
  line-height: 1em;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #fff;
  transform: translateY(-200px); /* Inicia fuera de la pantalla, más arriba */
  opacity: 0;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
