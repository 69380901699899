@import "../styles/styles.scss";

.main-layout {
  display: grid;
  grid-template-columns: 1fr;

  @media (max-width: $mediaLG) {
    display: block;
  }

  .main-wrapper{
    height: auto;
  }
}