@import 'styles.scss';

.coleccion-page {
    max-width: 1500px;
    width: 100%;
    margin: auto;
    padding: 0 2rem;
    margin-top: 130px;
    min-height: calc(100vh - 435px);
    * {
        font-family: $secondaryFont !important;
    }
    .section-header {
        height: 425px;
        background: linear-gradient(to bottom, #8A8A8A, #BEBEBE);

        .background {
            background-image: url('../assets/coleccion/background-header.png');
            background-repeat: no-repeat;
            background-position: center center;
            height: 100%;
            width: 100%;
        }
    }
    .section-filtres {
        height: 54px;
        max-height: 54px;

        .custom-checkbox, .custom-radio {
            opacity: 0;
            position: absolute;
            width: 0;
            height: 0;
        }

        .custom-checkbox-label, .custom-radio-label {
            padding-left: 20px;
            width: 25px;
            height: 25px;
            border: 1px solid $dark-grey;
            border-radius: 50%;
            background-color: white;
            cursor: pointer;
            position: relative;
            margin-right: 10px;
        }


        .custom-checkbox-label::after, .custom-radio-label::after {
            content: "";
            position: absolute;
            width: 60%;
            height: 60%;
            background-color: black;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 0.2s ease-in-out;
        }


        .custom-checkbox:checked + .custom-checkbox-label::after, .custom-radio:checked + .custom-radio-label::after {
            transform: translate(-50%, -50%) scale(1);
        }

        .badge-dark   {
            background: black;
            color: white;
            padding:3px 12px;
            border-radius: 12px;
            margin: 0 5px;
        }
        .accordion-button::after {
            margin-left:5px;
        }
        .filters-container {
            border-radius: 6px;
            margin-left: 3px;
            box-shadow: 0 0 10px silver;
            padding-right: 15px;

            .accordion-button:not(.collapsed) {
                background: transparent;
                border: 0;
                color: black;
            }
            .accordion-button:not(.collapsed)::after {
                background-image: var(--bs-accordion-btn-icon);
            }
        }
        .order-container {
            padding: 0;
        }
        .right-filters-container{
            gap: 10px;
        }

        .grill-buttons{
            display: flex;
            justify-content: center;
            align-items: center;
            button{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border-radius: 12px;
                background-color: white;
                border: 1px solid #D5D7DA;
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
                transition: all 0.2s ease-in-out;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 15px;
            }
            button:hover {
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
            }
            .grill-three-btn{
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.16667 7.16667C1.70833 7.16667 1.31583 7.00333 0.989167 6.67667C0.663055 6.35055 0.5 5.95833 0.5 5.5V2.16667C0.5 1.70833 0.663055 1.31583 0.989167 0.989167C1.31583 0.663055 1.70833 0.5 2.16667 0.5H5.5C5.95833 0.5 6.35083 0.663055 6.6775 0.989167C7.00361 1.31583 7.16667 1.70833 7.16667 2.16667V5.5C7.16667 5.95833 7.00361 6.35055 6.6775 6.67667C6.35083 7.00333 5.95833 7.16667 5.5 7.16667H2.16667ZM2.16667 15.5C1.70833 15.5 1.31583 15.3369 0.989167 15.0108C0.663055 14.6842 0.5 14.2917 0.5 13.8333V10.5C0.5 10.0417 0.663055 9.64917 0.989167 9.3225C1.31583 8.99639 1.70833 8.83333 2.16667 8.83333H5.5C5.95833 8.83333 6.35083 8.99639 6.6775 9.3225C7.00361 9.64917 7.16667 10.0417 7.16667 10.5V13.8333C7.16667 14.2917 7.00361 14.6842 6.6775 15.0108C6.35083 15.3369 5.95833 15.5 5.5 15.5H2.16667ZM10.5 7.16667C10.0417 7.16667 9.64944 7.00333 9.32333 6.67667C8.99667 6.35055 8.83333 5.95833 8.83333 5.5V2.16667C8.83333 1.70833 8.99667 1.31583 9.32333 0.989167C9.64944 0.663055 10.0417 0.5 10.5 0.5H13.8333C14.2917 0.5 14.6842 0.663055 15.0108 0.989167C15.3369 1.31583 15.5 1.70833 15.5 2.16667V5.5C15.5 5.95833 15.3369 6.35055 15.0108 6.67667C14.6842 7.00333 14.2917 7.16667 13.8333 7.16667H10.5ZM10.5 15.5C10.0417 15.5 9.64944 15.3369 9.32333 15.0108C8.99667 14.6842 8.83333 14.2917 8.83333 13.8333V10.5C8.83333 10.0417 8.99667 9.64917 9.32333 9.3225C9.64944 8.99639 10.0417 8.83333 10.5 8.83333H13.8333C14.2917 8.83333 14.6842 8.99639 15.0108 9.3225C15.3369 9.64917 15.5 10.0417 15.5 10.5V13.8333C15.5 14.2917 15.3369 14.6842 15.0108 15.0108C14.6842 15.3369 14.2917 15.5 13.8333 15.5H10.5ZM2.16667 5.5H5.5V2.16667H2.16667V5.5ZM10.5 5.5H13.8333V2.16667H10.5V5.5ZM10.5 13.8333H13.8333V10.5H10.5V13.8333ZM2.16667 13.8333H5.5V10.5H2.16667V13.8333Z' fill='%23252B37'/%3E%3C/svg%3E");
            }
            .grill-five-btn{
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.6667 0.666626H2.33341C1.41675 0.666626 0.666748 1.41663 0.666748 2.33329V15.6666C0.666748 16.5833 1.41675 17.3333 2.33341 17.3333H15.6667C16.5834 17.3333 17.3334 16.5833 17.3334 15.6666V2.33329C17.3334 1.41663 16.5834 0.666626 15.6667 0.666626ZM5.66675 15.6666H3.16675C2.70841 15.6666 2.33341 15.2916 2.33341 14.8333V12.3333H5.66675V15.6666ZM5.66675 10.6666H2.33341V7.33329H5.66675V10.6666ZM5.66675 5.66663H2.33341V3.16663C2.33341 2.70829 2.70841 2.33329 3.16675 2.33329H5.66675V5.66663ZM10.6667 15.6666H7.33342V12.3333H10.6667V15.6666ZM10.6667 10.6666H7.33342V7.33329H10.6667V10.6666ZM10.6667 5.66663H7.33342V2.33329H10.6667V5.66663ZM14.8334 15.6666H12.3334V12.3333H15.6667V14.8333C15.6667 15.2916 15.2917 15.6666 14.8334 15.6666ZM15.6667 10.6666H12.3334V7.33329H15.6667V10.6666ZM15.6667 5.66663H12.3334V2.33329H14.8334C15.2917 2.33329 15.6667 2.70829 15.6667 3.16663V5.66663Z' fill='%23252B37'/%3E%3C/svg%3E");
            }
        }

        .accordion-collapse {
            position: relative;
        }

        .accordion-item {
            border: 0;
        }

        .accordion-item:focus,
        button {
            box-shadow: none;
        }

        @media all and (min-width: $mediaMinLG) {
            .filters-container {
                .accordion-button {
                    padding-right:0 !important;
                }
            }
            .order-container {
                button {
                    box-shadow: 0 0 10px silver;
                    border-radius: 6px;
                }
                .accordion-button:not(.collapsed)::after {
                    background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clipRule="evenodd" d="M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z" fill="%23ffffff"/></svg>');
                    background-size: 30px;
                    background-position: center center;
                }
                .accordion-button:not(.collapsed) {
                    background: black;
                    color: white;
                }
            }
            .accordion-item,
            .accordion-collapse,
            .accordion-body {
                transition: none !important;
            }
            .accordion-body {
                position:absolute;
                padding: $dimenXS $dimenL;
                box-shadow: 0 0 10px silver;
                top:10px;
                left: 3px;
                min-width: 250px;
                background: white;
                z-index:4;
            }
            .accordion-body.filter-order {
                right: 10px;
                left: auto;
            }
        }


        .accordion-body ul {
            list-style: none;
            margin:0;
            padding:0;
        }
        .accordion-body li {
            padding: $dimenXXS;
            display: flex;
            align-items: center;
        }
    }

    .section-selected-filters {
        position: relative;
        width: 100%;

        .carousel-container {
            display: flex;
            overflow-x: auto;
            white-space: nowrap;
            padding-right: 85px;
            max-width: calc(100vw - 250px);

            /* Estilos para la barra de desplazamiento en Chrome */
            ::-webkit-scrollbar {
                width: 10px;
                background-color: transparent;
            }

            ::-webkit-scrollbar-thumb {
                background-color: transparent;
            }

            /* Estilos para la barra de desplazamiento en Firefox */
            @-moz-document url-prefix() {
                /* Solo se aplica a Firefox */
                scrollbar-color: transparent transparent;
            }

            .btn-delete-filters {
                background: black;
                border: 0;
                border-radius: 36px;
                color: white;
                padding: 0 30px 0  20px;
                margin-right: 8px;
                position: relative;

            }
            .btn-delete-filters::after {
                content: "";
                position: absolute;
                top: 51%;
                right: 12px;
                transform: translateY(-50%);
                width: 12px;
                height: 12px;
                background: black;
                background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="iso-8859-1"%3F><svg fill="%23ffffff" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve"><g><g><polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 512,452.922 315.076,256 "/></g></g></svg>');
                background-repeat: no-repeat;
                background-size: contain;
            }
            .carousel-content {
                flex-shrink: 0;
                border: 1px solid black;
                border-radius: 36px;
                padding: $dimenXXXS $dimenS;
                margin-right: $dimenXXS;
                span {
                    color: silver;
                    font-weight: bold;
                    margin-right: $dimenXXXS;
                }
                .btn-close {
                    opacity: 1;
                    margin-left: $dimenXXS;
                    width: 15px !important;
                    height:15px !important;
                    padding: 0;
                }
            }
        }

        .btn-prev,
        .btn-next {
            background: black;
            width: 30px;
            height: 30px;
            background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clipRule="evenodd" d="M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z" fill="%23ffffff"/></svg>');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            border: 0;
            border-radius: 50%;
            position:absolute;
            top:0;
            bottom:0;
            margin-top: auto;
            margin-bottom: auto;
        }
        .btn-next {
            right:20px;
            transform: rotate(270deg);
        }
        .btn-prev {
            right: 55px;
            transform: rotate(90deg);
        }

    }

    .artworks-container.container-5 {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 24px;
        @media all and (max-width: $mediaLG) {
            grid-template-columns: repeat(4, 1fr);
            gap: 8px;
        }
        @media all and (max-width: $mediaXS) {
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;
        }
    }

    .artworks-container.container-3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        @media all and (max-width: $mediaLG) {
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;
        }
        @media all and (max-width: $mediaXS) {
            grid-template-columns: repeat(1, 1fr);
            gap: 24px;
        }
    }

    .artworks-container {
        margin-top: 32px;
        margin-bottom: 200px;
        .mona {
            position:relative;
            .mona-wrapper {
                position: relative;
                overflow: hidden;
                align-content: center;
                width: 100%;
                aspect-ratio: 1/1;
                .mona-image {
                    width: 100%;
                    height: auto;
                    border-radius: 4px;
                    z-index: 1;
                }
            }

            .ranking {
                position:absolute;
                bottom: 10%;
                border-radius: 36px;
                padding:7px 15px;
                left: 5%;
                background: white;
                color: black;
                font-weight: bold;
            }
            .mona-overlay {
                background: rgba(0, 0, 0, 0.5);
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                width: 100%;
                height: 100%;
                z-index: 3;
                padding: 16px;

                .overlay-section {
                    width: 100%;
                    height: 33%;
                    display: flex;
                }

                .overlay-section.fav-container {
                    height: 34%;
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                .overlay-section.cart-container {
                    align-items: flex-end;
                    justify-content: center;
                }

                .overlay-section.profile-container {
                    align-items: flex-end;
                    justify-content: center;

                    a {
                        font-size: 16px;
                        line-height: 24px;
                        text-decoration: underline;
                    }
                }

                .btn-add-cart {
                    background-color: white;
                    color: #333;
                    border: none;
                    padding: 10px 16px;
                    border-radius: 12px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    font-weight: bold;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    transition: transform 0.2s, box-shadow 0.2s;
                }

                .btn-add-cart:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
                  }

                  .btn-add-cart:active {
                    transform: translateY(1px);
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                  }

                a {
                    color: white;
                    font-weight: bold;
                    margin-top: 8px;
                    text-decoration: none;
                    font-size: 14px;
                }
                a:hover {
                    text-decoration: underline;
                }
            }

            .mona-overlay:hover {
                opacity: 1;
            }

            .fav-number-container {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                padding: 16px;
                z-index: 2;

                .fav-container {
                    height: 50%;
                    align-content: flex-start;
                    justify-content: flex-start;
                    display: grid;
                }

                .number-container {
                    height: 50%;
                    align-content: flex-end;
                    justify-content: flex-end;
                    display: grid;
                }

                .number {
                    background: black;
                    color: white;
                    border-radius: 36px;
                    padding: 6px 12px;

                    .status-dot {
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        display: inline-block;
                        margin-right: 4px;
                      }

                      .status-dot.red {
                        background-color: red;
                      }

                      .status-dot.orange {
                        background-color: orange;
                      }
                }
            }
        }
    }

    .artworks-container.artworks-container-marginless{
        margin: 0;
    }

    .custom-toast-container {
        background-color: #05D777 !important;
        color: white;
        border-radius: 16px;
        padding: 12px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: auto;
        min-width: 644px;

        .custom-toast {
            display: flex;
            align-items: center;
            gap: 12px;
            justify-content: space-between;

            .toast-info {
                display: flex;
                gap: 12px;

                .toast-text{
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            .view-favorites {
                background: transparent;
                color: white;
                font-weight: bold;
                border: none;
                cursor: pointer;
                margin-right: 20px;
            }
                .view-favorites:hover {
                text-decoration: underline;
            }
            .close-icon {
                cursor: pointer;
                color: white;
            }
        }
    }

    .Toastify__toast-container {
        padding: 0;
        left:auto;
    }

    .next-render  {
        height: 400px;
        width: 100%;
    }

    .no-results {
        margin-bottom: 100px;
        color: $grey-submit;
        h2 {
            color: gray;
        }
    }

    .section-availability{
        margin-top: 36px;

        .disponibility-filter-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            background: #F6F6F6;
            border-radius: 50px;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
            border: none;
            box-shadow: 0 0 10px silver;

            .switch-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;
                background: #FFFFFF;
                padding: 14px 12px;
                border-radius: 50px;
                transition: background 0.3s ease-in-out;
                border: 1px solid #DDD5D5;
            }

            .toggle-switch {
                position: relative;
                display: inline-block;
                width: 42px;
                height: 22px;
                cursor: pointer;

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .slider {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ddd;
                    border-radius: 30px;
                    transition: 0.4s;
                    border: 1px solid #ccc;
                }

                .slider:before {
                    content: "";
                    position: absolute;
                    height: 16px;
                    width: 16px;
                    left: 3px;
                    bottom: 2px;
                    background-color: white;
                    border-radius: 50%;
                    transition: 0.4s;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
                }

                input:checked + .slider {
                    background-color: #4caf50;
                    border-color: #4caf50;
                }

                input:checked + .slider:before {
                    transform: translateX(20px);
                }

            }

            .filter-label {
                font-size: 16px;
                font-weight: bold;
                color: #333;
            }

            .status-indicators {
                display: flex;
                align-items: center;
                gap: 12px;
                font-size: 14px;
                color: #333;
                margin-right: 28px;
            }

            .status-dot {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                display: inline-block;
                border: 4px solid black;
                margin-right: 4px;
            }

            .status-dot.red {
                background-color: red;
            }

            .status-dot.orange {
                background-color: orange;
            }
        }
    }
}

.coleccion-page.coleccion-page-second {
    margin-top: 43px;
}

@media all and (max-width: $mediaLG) {
    body.no-scroll {
        overflow-y: hidden;
    }
    .coleccion-page {
        padding-bottom: 50px;
        .artworks-container {
            .mona {
                .fav-number-container {
                    padding: 12px;
                }
            }
        }

        .section-header {
            height: auto;
            width: 100%;
            padding-top: 100%;
            background: #8A8A8A;
            position: relative;

            .background {
                position: absolute; /* Permite posicionar el contenido dentro del div */
                top: 30px;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url('../assets/coleccion/background-header-mobile.png');
                background-repeat: no-repeat;
                background-position: bottom center;
                background-size: 100%;

            }
        }
        .section-selected-filters {
            display: none !important;
        }

        .section-filtres {
            padding-top: 15px;
            padding-left: 20px;

            .btn-close {
                background:transparent !important;
                color: black;
                opacity: 1;
            }
            .order-container {
                .accordion-item,
                .accordion-collapse,
                .accordion-body {
                    transition: none !important;
                }
                .accordion-button {
                    text-indent: -9999px;
                    background: white;
                    box-shadow: 0 0 10px silver;
                    border: 0;
                    height: 48px;
                    width: 48px;
                    border-radius: 4px;
                }
                .collapse:not(.show) {
                    display: block;
                }
                .accordion-body > div {
                    position:fixed;
                    top:0px;
                    left: 0px;
                    width: 100vw;
                    height: 100vh;
                    background: white;
                    z-index: 11;
                    transition: transform 0.5s, visibility 0.5s;
                    visibility: visible;
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                }
                .accordion-body > div.hidden {
                    transition: transform 0.5s, visibility 0.5s;
                    visibility: hidden;
                    -webkit-transform: translate3d(0, -100%, 0);
                    transform: translate3d(0, -100%, 0);
                }
                .accordion-button {
                    transition: none !important;
                }
                .accordion-button:not(.collapsed) {
                    background: white;
                }
                .accordion-button::after {
                    transition: none !important;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url('../assets/coleccion/btn-order-movile.png') !important;
                    background-position: center center;
                    margin-left: 0 !important;
                }
            }
            .filters-container.show {
                visibility: visible;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
            .filters-container {
                display:flex;
                margin:0 !important;
                padding:0;
                height: 100vh;
                width: 100vw;
                position: fixed;
                top:0;
                left:0;
                z-index: 10;
                background: white;
                transition: all .5s;
                visibility: hidden;
                -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
            }
            .filters-container > div {
                width: 100%;
                overflow-y: auto;
                padding-bottom: 120px;
            }
            .btn-filters-container button {
                background:white;
                box-shadow: 0 0 10px silver;
                border: 0;
                height: 48px;
                width: 48px;
                border-radius: 4px;
            }
        }
        .artworks-container {
            padding-left: 16px;
        }
        .navbar-purchase,
        .navbar-filters {
            position: fixed;
            bottom: 0;
            left: 0;
            background: white;
            width: 100vw;
            text-align: center;
            padding: 20px;
            box-shadow: 0 0 10px silver;
            z-index: 9;

            button {
                border: 1px solid black;
                width: 100%;
                border-radius: 6px;
                padding: 7px 0;
                background: white;
                color: black;
            }
            button.black {
                background: black;
                color: white;
                border: 0;
                svg {
                    width: 30px;
                    height: 30px;
                    transform: scaleX(-1);
                    margin-right: 5px;
                }
            }

            p {
                padding-top:15px;
                margin: 0;
                padding-bottom: 0;
                font-weight: bold;
            }
        }
    }
}
@media (max-width: 991px) {

    .coleccion-page {
        margin-top: 80px;

        .all-filters-container {
            position: relative;
            height: 140px;
            .button-select-filters {
                position: relative;
                span {
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    color: white;
                    background: black;
                    display: block;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    font-size: 10px;
                }
            }
            .disponibility-filter-container {
                position: absolute;
                top: 10px;
                right: 0;
                box-shadow: none !important;
                .switch-wrapper  {
                    border: 0 !important;
                }
            }
            .right-filters-container {
                position:absolute;
                bottom: 0;
                width: 100%;
                left: 0;
                .grill-buttons {
                    width: 50%;
                    button {
                        width: 100%;
                        border: 0;
                        box-shadow: none;
                        background-size: 25px;
                        border-radius: 0;
                    }
                    button.active {
                        background-color: #F5F5F5;
                        border-bottom: 1.5px solid #4BB3C5;
                    }
                }
            }
        }

        .section-filtres {
            padding-left: 0;
            .btn-filters-container,
            .order-container {
                margin-top: -5px;
            }
            .order-container button,
            .btn-filters-container button {
                width: 50px !important;
                height: 50px !important;
                border-radius: 12px !important;
                background-color: white !important;
                border: 1px solid #D5D7DA !important;
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) !important;
            }
        }
        .artworks-container {
            padding-left: 0;
        }

    }
}
