@import "./homeStyles";
@import "../../styles/styles.scss";

.Wrapp {
  @include grid-container(repeat(12, 1fr), $gap-lg);
  background: #000000b2;
  /* border-radius: 16px; */
  z-index: 1;
  opacity: 0;
  max-height: 800px;
  /* margin: 2rem; */
  @include grid-start-end(1, 13, 1, 13);

  @include media-query($max-width-desktop-large) {
    @include grid-start-end(1, 13, 1, 13);
  }
  @include media-query($max-width-desktop) {
    @include grid-start-end(1, 13, 1, 13);
  }
  @include media-query($max-width-tablet) {
    @include grid-start-end(1, 7, 1, 13);
    /* margin: 2rem 1rem; */
  }
  @include media-query($max-width-mobile) {
    @include grid-start-end(1, 3, 1, 13);
  }
}

.close {
  color: white;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  margin-top: 2rem;
  @include grid-start-end(12, 13, 1, 2);

  @include media-query($max-width-desktop-large) {
    @include grid-start-end(12, 13, 1, 2);
    margin-right: 2rem;
    margin-top: 2rem;
  }
  @include media-query($max-width-desktop) {
    @include grid-start-end(12, 13, 1, 3);
  }
  @include media-query($max-width-tablet) {
    @include grid-start-end(6, 7, 1, 3);
  }
  @include media-query($max-width-mobile) {
    @include grid-start-end(2, 3, 1, 2);
    justify-content: flex-end;
    margin-right: 2rem;
    margin-top: 2rem;
  }
}

.modalBackground {
  position: fixed;
  z-index: 10;
  left: 0rem;
  top: 0rem;
  right: 0rem;
  bottom: 0rem;
  /* width: 100%; */
  height: 100%;
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  margin: 0 !important;
  overflow: hidden;
  padding: 3rem;
  box-sizing: border-box;

  @include media-query($max-width-tablet) {
    padding: 1rem;
  }
}

.modalContainer {
  margin: 0px;
  max-width: 1240px;
  width: 100%;
  height: 100%;
  background: #000000b2;
  border-radius: 12px;
  border: 0px;
  position: relative;

  @include grid-container(repeat(24, 1fr), null);

  @include media-query($max-width-mobile) {
    padding: 2rem;
  }
}

.CloseContainer {
  top: 3rem;
  right: 3rem;
  position: absolute;
  cursor: pointer;

  @include media-query($max-width-tablet) {
    top: 1rem;
    right: 1rem;
  }
}

.Col1 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem;
  background-color: #fff;
  border-radius: 12px;
  @include grid-start-end(2, 6, 15, 22);

  &.Col1Extend {
    @include grid-start-end(2, 7, 14, 23);
  }

  @include media-query($max-width-desktop-large) {
    @include grid-start-end(2, 7, 15, 22);
  }
  @include media-query($max-width-desktop) {
    @include grid-start-end(2, 12, 15, 22);
    &.Col1Extend {
      @include grid-start-end(2, 7, 13, 23);
    }
  }
  @include media-query($max-width-tablet) {
    @include grid-start-end(2, 6, 15, 24);
    &.Col1Extend {
      @include grid-start-end(2, 6, 15, 24);
    }
  }
  @include media-query($max-width-mobile) {
    @include grid-start-end(1, 3, 15, 24);
    &.Col1Extend {
      @include grid-start-end(1, 3, 14, 24);
    }
    margin: 1rem;
    padding: 1rem;
  }

  & .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-family: $font15;
    gap: 8px;
    /* opacity: 0; */

    h3 {
      font-family: $font15;
      font-weight: bold;
    }

    button {
      width: 97;
      height: 40;
      border-width: 1px;
      border-radius: 8px;
      padding-top: 8px;
      padding-right: 14px;
      padding-bottom: 8px;
      padding-left: 14px;
      gap: 8px;
      color: #fff;
      background-color: #000;
    }
  }
}

.Col2 {
  display: flex;
  align-items: center;
  justify-content: center;
  @include grid-start-end(6, 13, 2, 7);

  @include media-query($max-width-desktop-large) {
    @include grid-start-end(6, 12, 2, 14);
    &.Col2Extend {
      @include grid-start-end(7, 12, 2, 14);
    }
  }
  @include media-query($max-width-desktop) {
    @include grid-start-end(4, 10, 1, 12);
    &.Col2Extend {
      @include grid-start-end(5, 11, 1, 12);
    }
  }
  @include media-query($max-width-tablet) {
    @include grid-start-end(1, 7, 1, 13);
    &.Col2Extend {
      @include grid-start-end(2, 5, 1, 13);
    }
  }
  @include media-query($max-width-mobile) {
    @include grid-start-end(1, 3, 2, 14);
    &.Col2Extend {
      @include grid-start-end(1, 3, 2, 14);
    }
  }

  & img {
    width: 100%;
  }
}

.modalConector {
  width: 100%;
  height: 100%;
  border-left: 2px solid #fff;
  border-top: 2px solid white;
  position: relative;
  @include grid-start-end(4, 7, 11, 15);
  &.modalConectorExtend {
    @include grid-start-end(4, 8, 7, 15);
  }

  @include media-query($max-width-desktop-large) {
    @include grid-start-end(3, 7, 7, 15);
    &.modalConectorExtend {
      @include grid-start-end(4, 8, 7, 15);
    }
  }
  @include media-query($max-width-desktop) {
    margin-left: 2rem;
    @include grid-start-end(2, 5, 7, 16);
    &.modalConectorExtend {
      @include grid-start-end(3, 5, 7, 16);
    }
  }
  @include media-query($max-width-tablet) {
    @include grid-start-end(4, 4, 11, 15);
    &.modalConectorExtend {
      @include grid-start-end(4, 4, 10, 15);
    }
    margin-left: 0;
    width: 1px;
  }
  @include media-query($max-width-mobile) {
    @include grid-start-end(2, 3, 11, 16);
    &.modalConectorExtend {
      @include grid-start-end(2, 3, 11, 16);
    }
    width: 1px;
  }

  & .point {
    width: 20px;
    height: 20px;
    border-width: 0.86px;
    border-radius: 64px;
    background-color: #ffffff00;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -10px;

    & .pointInner {
      width: 11px;
      height: 11px;
      border-radius: 100px;
      background: white;
    }
  }
}
