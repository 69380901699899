@import "../../styles/styles.scss";
@import "./homeStyles";

.Container {
  display: none;
  @include media-query($max-width-mobile) {
    display: inline-block;
    width: 100%;
    padding: 2rem 1.5rem 0 2rem;
    background-color: transparent;
  }
}

.ShopButton {
  display: none;
  @include media-query($max-width-mobile) {
    display: inline-block;
    padding: 12px;
    background-color: #000;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 150px;
    width: 100%;
  }
}

.ShopButton:active {
  background-color: #333;
}

.ShopButton, 
.ShopButton:link, 
.ShopButton:visited, 
.ShopButton:hover, 
.ShopButton:active {
  text-decoration: none;
}