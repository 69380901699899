$max-width-desktop-large: 1500px;
$max-width-desktop: 1120px;
$max-width-tablet: 768px;
$max-width-mobile: 520px;

$breakpoints: (
  "desktop-large": $max-width-desktop-large,
  "desktop": $max-width-desktop,
  "tablet": $max-width-tablet,
  "mobile": $max-width-mobile,
);

$gap-xl: 32px;
$gap-lg: 24px;
$gap-md: 16px;
$gap-xs: 12px;

$spacing-xl: 32px;
$spacing-lg: 24px;
$spacing-md: 16px;
$spacing-xs: 12px;

$spacing: (
  "desktop-large": $spacing-xl,
  "desktop": $spacing-lg,
  "tablet": $spacing-md,
  "mobile": $spacing-xs,
);

$cols: (
  "desktop-large": repeat(12, 1fr),
  "desktop": repeat(12, 1fr),
  "tablet": repeat(6, 1fr),
  "mobile": repeat(2, 1fr),
);

@mixin media-query($break: null) {
  @if $break == $max-width-mobile {
    @media (max-width: $max-width-mobile) {
      @content;
    }
  } @else if $break == $max-width-tablet {
    @media only screen and (max-width: $max-width-tablet) {
      @content;
    }
  } @else if $break == $max-width-desktop {
    @media only screen and (max-width: $max-width-desktop) {
      @content;
    }
  } @else if $break == $max-width-desktop-large {
    @media only screen and (max-width: $max-width-desktop-large) {
      @content;
    }
  }
}

@mixin grid-container($rows: null, $gap: null) {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: $rows;
  gap: $gap;
  /* padding: 0 $spacing-xl; */
  max-width: $max-width-desktop-large;
  margin: auto;

  @each $break, $size in $breakpoints {
    @media (max-width: $size) {
      grid-template-columns: map-get($cols, $break);
      /* padding: 0 map-get($spacing, $break); */
    }
  }
}

@mixin grid-start-end(
  $grid-column-start: null,
  $grid-column-end: null,
  $grid-row-start: null,
  $grid-row-end: null
) {
  display: grid;
  grid-column-start: $grid-column-start;
  grid-column-end: $grid-column-end;
  grid-row-start: $grid-row-start;
  grid-row-end: $grid-row-end;
}

/* $grid-container-12: grid-container(repeat(12, 1fr), auto, $gap-lg);
$grid-container-6: grid-container(repeat(6, 1fr), auto, $gap-lg);
$grid-container-5: grid-container(repeat(5, 1fr), auto, $gap-lg);
$grid-container-3: grid-container(repeat(5, 1fr), auto, $gap-lg);
$grid-container-2: grid-container(repeat(5, 1fr), auto, $gap-lg); */
