@import 'styles.scss';

*:focus {
  outline: none;
}

#redeem-page {
  background: linear-gradient(225deg, #485362 0%, #151A21 88.54%);
  margin: 0px;
  padding: 0px;
  //min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

.redeem {
  gap: 100px;
  min-height: calc(100vh - 100px);
}

.redeem-card-box {
  position: relative;
  margin: 0px;
  width: 546px;
  height: 491px;
}

.redeem-card-back-left, .redeem-card-back-right {
  position: absolute;
  top: 22px;
  margin: 0px;
  width: 447px;
  height: 447px;
  background: $grey-redeem;
  border: 6px solid $white;
  border-radius: 20px;
  filter: drop-shadow(0px 7.81579px 40px #5B5D72);
}

.redeem-card-back-left {
  left: 0px;
}

.redeem-card-back-right {
  right: 0px;
}

.redeem-card {
  position: relative;
  margin: 0px;
  width: 491px;
  height: 491px;
  background: $grey-redeem;
  border: 6px solid $white;
  border-radius: 20px;
  filter: drop-shadow(0px 7.81579px 40px #5B5D72);
}

.redeem-card svg {
  margin: 0px;
  width: 100%;
  height: 100%;
}

.redeem-left {
  margin: 0px;
  margin-top: 32px;
  //position: relative;
  > svg {
    position: relative;
    bottom: -100px;
    right: 0px;
  }
}

.redeem-right {
  margin: 0px;
  width: 512px;
  min-height: 491px;
  margin-top: 120px;
}

.redeem-right > h4 {
  margin: 0px;
  color: #86CAF4;
  font-family: $secondaryFont;
  font-size: 31.002px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 99.995% */
  white-space: nowrap;
}

.redeem-right > h2 {
  margin: 0px 0px $dimenXS 0px;
  color: $white;
  font-family: $primaryFont;
  font-size: 100px;
  font-style: normal;
  font-weight: 400;
  line-height: 100px; /* 100% */
  white-space: nowrap;
}

.redeem-right > h3, .redeem-left h3 {
  margin: 0px 0px $dimenXXXL 0px;
  color: $grey-redeem;
  font-family: $secondaryFont;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 150% */
}

.redeem-code {
  position: relative;
  margin: 0px 0px $dimenXL 0px;
  width: 100%;
  height: 48px;
}

.redeem-code button {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0px;
  padding: 0px 12px;
  height: 48px;
  width: 180px;
  background: $white;
  border-radius: 0px 6px 6px 0px;
  border: 0px;
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: $black;
}

.redeem-code input {
  margin: 0px;
  padding: $dimenXXS calc(180px + $dimenXXS) $dimenXXS $dimenXXS;
  height: 48px;
  width: 100%;
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: $white;
  border-radius: 6px;
  border: 1px solid $white;
  background: transparent;
}

.error-code {
  margin: $dimenXXXS 0px 0px 0px;
  height: 16px;
  color: $red-error;
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.redeem-right p, .checkout-right > p,
.redeem-left p, .checkout-left > p {
  margin: 0px;
  color: $white;
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}

.redeem-right a, .checkout-right p > a,
.redeem-left a, .checkout-left p > a {
  margin: 0px;
  color: $white;
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.checkout-right > p, .checkout-right p > a {
  color: $black;
}

.redeem-right a:visited, .redeem-right a:hover, .redeem-right a:active {
  color: $white;
}

.checkout-right p > a:visited, .checkout-right p > a:hover, .checkout-right p > a:active {
  color: $black;
}

.redeem-right > button, .discover-button-container > a, .checkout-bottom-right-buttons > button {
  margin: 0px 0px $dimenS 0px;
  padding: $dimenS $dimenXXS;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 0px;
  background-color: $white;
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $white;
  background: $black;
}

.redeem-right > button {
  margin: $dimenXXL 0px $dimenS 0px;
  background: $white;
  color: $black;
}

.checkout-bottom-right-buttons > button {
  background: $white;
  color: $black;
}

.checkout-bottom-right-buttons > button:last-child {
  background: transparent;
  color: $white;
  border: 1px solid $white;
}

.redeem-right > button:hover, .redeem-code button:hover, .discover-button-container > a:hover,
.checkout-bottom-right-buttons > button:hover {
  opacity: 0.82;
}

.redeem-right > button:active, .redeem-code button:active, .discover-button-container > a:active,
.checkout-bottom-right-buttons > button:active {
  opacity: 0.64;
}

.redeem-right > button:disabled, .discover-button-container > a:disabled,
.checkout-bottom-right-buttons > button:disabled {
  border: 0px;
  background: $grey;
  color: $grey-submit;
  cursor: default;
}

.redeem-right > button:disabled:hover, .redeem-right > button:disabled:active, .discover-button-container > a:disabled:hover,
.discover-button-container > a:disabled:active {
  opacity: 1;
}

.redeem-codes-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.redeem-codes-item-box {
  margin: 0px;
  padding: $dimenXXS 0rem;
  width: 100%;
  min-height: 52px;
  //border: 1px solid $white;
  //border-radius: 6px;
  background: transparent;
  gap: 14px;
}

.redeem-codes-item hr {
  margin: 0px;
  width: 100%;
  color: $white;
}

.redeem-codes-item-box svg {
  margin: 0px;
  min-width: 20px;
  min-height: 20px;
}

.redeem-codes-item-box h3 {
  margin: 0px;
  padding: 0px;
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $white;
}

.redeem-codes-item-error {
  margin: 9px 0px $dimenS 0px;
  height: 16px;
  font-family: $secondaryFont;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: $marfil;
  gap: $dimenXXS
}

.redeem-form {
  //margin-top: $dimenXXXL;
}

.redeem-checkout {
  padding: $dimenXXXL $dimenXL $dimenXXL $dimenXL;
  width: 100%;
  gap: $dimenS;
  .title-redeem-checkout {
    margin: 0rem;
    width: 100%;
    gap: $dimenM;
    .buyer-form {
      margin: 0rem;
      width: 100%;
    }
    .buyer-form-container {
      margin: 0rem;
      width: 100%;
    }
    .buyer-form-container-field {
      margin: 0px;
      height: 68px;
      width: 100%;
      max-width: calc(50% - ($dimenM / 2));
      p {
        margin: 0rem;
        color: $white;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }
    }
    .buyer-form-container-field-one {
      margin: 0px;
      height: 68px;
      width: 100%;
      max-width: none;
    }
    .buyer-form-container {
      gap: $dimenM;
      svg {
       top: 33px !important;
      }
      input {
        width: 100%;
        height: 48px !important;
      }
    }
    h4 {
      margin: 0rem;
      color: $white;
      font-family: $secondaryFont;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 166.667% */
    }
    hr {
      margin: 0px;
      width: 100%;
      color: $white;
    }
  }
  .checkout-bottom {
    width: 100%;
    gap: $dimenXXXL;
    .checkout-bottom-left {
      width: 100%;
      min-width: 312px;
      max-width: calc(100% - 450px);
      gap: $dimenS;
      h3 {
        margin: 0rem;
        color: $white;
        font-family: $secondaryFont;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      h5 {
        margin: 0rem;
        color: $white;
        font-family: $secondaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 150% */
      }
    }
    .checkout-bottom-right {
      width: 340px;
      padding-left: $dimenXL;
      padding-right: $dimenXL;
    }
  }
  .checkout-checkboxes {
    margin: 0px;
  }
  .checkout-checkboxes label, .checkout-checkboxes label span {
    color: $white !important;
  }
  .checkout-checkboxes-item .checkmark:after {
    width: 5px;
    height: 10px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .checkmark {
    border: 2px solid $white;
  }
}

.checkout-bottom-right {
  gap: $dimenL;
  width: 100%;
}

.checkout-bottom-right-box {
  margin: 0rem;
  padding: $dimenL $dimenXL;
  width: 100%;
  border-radius: 8px;
  background: $white;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  gap: $dimenXXXS;
  > * {
    width: 100%;
  }
  h3 {
    margin: 0rem;
    color: $black;
    font-family: $secondaryFont;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 100% */
  }
  hr {
    margin: 0rem;
  }
  h4 {
    margin: 0rem;
    color: $black;
    font-family: $secondaryFont;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}

.redeem-form h6 {
  width: 437px;
  color: $white;
}

/* On mouse-over, add a grey background color */
.redeem-form input[type=checkbox]:hover ~ .checkmark {
  background-color: $dark-purple;
}

/* When the checkbox is checked, add a blue background */
.redeem-form input[type=checkbox]:checked ~ .checkmark {
  background-color: $dark-purple;
}

.loading-code {
  position: absolute;
  top: 13px;
  right: 24px;
  margin: 0px;
  height: 36px;
  width: 76px;
  border-radius: 2px;
  border: 0px;
  background: $black;
  gap: 8px;
}

.loading-code div {
  margin: 0px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 0px;
  background: $white;
  animation: code-loading-default 1500ms linear infinite;
}

.loading-code div:nth-child(1) {
  animation-delay: 0s;
}

.loading-code div:nth-child(2) {
  animation-delay: 500ms;
}

.loading-code div:nth-child(3) {
  animation-delay: 1000ms;
}

@keyframes code-loading-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
    background: $grey-submit;
  }
  50% {
    transform: scale(1.5);
    background: $white;
  }
}

@media (max-width: 1200px) {

  .redeem {
    gap: 12px;
  }

}

@media (max-width: $mediaLG) {

  #redeem-page {
    margin-top: 80px;
  }

  .redeem {
    gap: 36px;
    padding-top: 24px;
    min-height: calc(100vh - 126px);
  }

  .redeem-left {
    margin: 0px;
    > svg {
      position: relative;
      bottom: -100px;
      right: 0px;
      width: 60%;
      height: 60%;
    }
  }

  .redeem-right {
    margin: 0px;
    width: 100%;
    max-width: 512px;
    min-height: 0px;
  }

  .redeem-right > h4 {
    margin: 0px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px; /* 140.909% */
    white-space: normal;
    text-align: center;
  }

  .redeem-right > h2 {
    margin: 0px 0px $dimenXS 0px;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 100% */
    white-space: normal;
    text-align: center;
  }

  .redeem-right > h3 {
    margin: 0px 0px $dimenXXXL 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    text-align: center;
  }

  .redeem-code > button {
    width: 116px;
  }

  .redeem-code > input {
    padding-right: 124px;
  }

  .redeem-card-box {
    width: 343px;
    height: 343px;
  }

  .redeem-card {
    margin: 0px;
    width: 343px;
    height: 343px;
    border: 6px solid $white;
  }

  .redeem-card svg {
    margin: 0px;
    //width: 312px;
    //height: 249px;
  }

  .redeem-right p, .redeem-left p {
    text-align: center;
  }

  .redeem-codes-item-error {
    min-height: 16px;
    height: auto;
    //text-align: center;
  }

  .redeem-form {
    margin-top: 0px;
  }

  .redeem-form h6 {
    width: 347px;
  }

  .back-redeem-text {
    width: 100%;
  }

  .redeem-checkout {
    padding: $dimenXXL $dimenXL;
    .buyer-form-container-field {
      max-width: none !important;
    }
  }

}