@import "../../styles/styles.scss";
@import "./homeStyles";

.NavbarV2 {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  transition: 0.2s;
  background-color: white;
}

.NavbarV2.bg {
  background-color: white;
  transition: 0.2s;
  box-shadow: 0px 4px 8px -2px #0A0D121A;
}

.Wrapp {
  max-width: $max-width-desktop-large;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  margin: auto;
}
.Logo {
  width: 230px;
}

.Nav {
  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    & li {
      color: #a4a7ae;
      font-weight: 700;
      font-family: $primaryFont;
      margin: 0;
      cursor: pointer;
    }

  }
}

.Actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: $primaryFont;

  .BtnLogin {
    background: transparent;
    color: #414651;
    border: 1px solid #d5d7da;
    border-radius: 8px;
    height: 44px;
    font-weight: 700;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .BtnShop {
    height: 44px;
    padding: 12px;
    border-radius: 8px;
    background: transparent;
    border: 1px solid white;
    color: white;
    font-family: $primaryFont;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 1px solid #d5d7da;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.MobileNavbarWrapp {
  & button {
    margin-left: 10px;
  }
  .MobileNavbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.2s;
  }
  .MobileNavbar.bg {
    transition: 0.2s;
    background-color: white;
    box-shadow: 1px 0px 5px black;
    z-index: 9;
  }
  .MobileNavbarWrapp {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 2rem;

    svg {
      cursor: pointer;
      color: #fff;
    }
  }

  .MobileNavbar__Content {
    width: 100%;
    height: 100vh;
    background-color: #1110107a;
    backdrop-filter: blur(10px);
    padding: 24px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    transform: translateX(-2000px);
    transition: 0.4s;



    & header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .MobileNavbar_Header {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;

        .BtnClose {
          border: 2px solid white;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .headerLogo > svg {
          padding-left: 12px;
          margin-top: 5px;
          width: 200px;
        }
      }

      .BtnShop {
        background: transparent;
        border: 1px solid white;
        color: white;
        font-weight: 700;
        padding: 16px 24px;
        border-radius: 8px;
        text-transform: uppercase;
      }
    }

    & ul {
      padding: 0;
      margin: 0;
      list-style: none;

      & li {
        color: white;
        font-weight: 700;
        font-family: $primaryFont;
        font-size: 24px;
        margin: 0;
        border-bottom: 1px solid white;
        padding: 32px 0;
        cursor: pointer;
      }
    }

    .MobileSocialLinks {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      & span {
        color: white;
        font-family: $secondaryFont;
      }
    }
  }

  .MobileNavbar__Content.Open {
    transform: translateX(0);
    transition: 0.4s;
  }
}
