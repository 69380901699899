@import "../../pages/home/homeStyles";
@import "../../styles/styles.scss";

.footerv2Wrapper {
  background: #000;
}
.footerv2 {
  padding: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  max-width: $max-width-desktop-large;

  @include media-query($max-width-desktop) {
    flex-direction: column;
    gap: 3rem;
    text-align: center;
  }
  @include media-query($max-width-tablet) {
    flex-direction: column;
    gap: 3rem;
    text-align: center;
  }
}

.footerv2List {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 2;

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0;
    color: #fff;

    @include media-query($max-width-tablet) {
      justify-content: center;
    }

    & li {
      position: relative;
      padding-right: 12px;
      font-family: $secondaryFont;
      cursor: pointer;
    }
    & li:hover {
      text-decoration: underline;
    }

    & li::after {
      content: "|";
      position: absolute;
      right: 0px;
      top: 0;
    }

    & li:last-child::after {
      content: "";
    }
  }

  span {
    color: #fff;
  }
}

.footerv2Logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
}

.footerv2SocialLinks {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;

  & .footerv2Icons {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
