@import "styles.scss";

.itemPage {
  *{
    font-family: $secondaryFont;
  }
}
@media (max-width: 991px) {
  .mdHide {
    display: none !important;
  }
  .mdFlexColumn {
    flex-direction: column;
  }
  .mdJustifyContentCenter {
    justify-content: center;
  }
  .mdMT-3 {
    margin-top: 1rem;
  }
  .mdW-100 {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .lgFlex {
    display: flex;
  }
  .lgHide {
    display: none !important;
  }
}
.btnSmallSquare {
  background-color: black;
  border: 0;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnWhite {
  background-color: white;
}
#item-section {
  padding: 70px 48px 70px 48px;
  @media (max-width: 992px) {
    padding: 70px 0px 70px 0px;
  }
  @media (max-width: 576px) {
    padding: 70px 16px 70px 16px !important;
  }
}
.titulo-item {
  color: $black;
  font-style: normal;
  font-weight: 400;
  font-size:52px;
  line-height: 65px;
}
@media (max-width: 991px) {
  .titulo-item {
    font-size: 32px;
  }
}
.secondaryFont {
  font-family: $secondaryFont;
}
.primaryFont {
  font-family: $primaryFont;
}
.colorAqua {
  color: #4BB3C4;
}
.wrapper-item-page {

  margin-top: 120px;

  .carouselShop::-webkit-scrollbar {
    display: none;
  }
  .carouselShop {
    scrollbar-width: none;
  }

  .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
  }
  .carousel-control-next,
  .carousel-control-prev {
    width: 7%;
  }
}
.linkBread {
  text-decoration: none;
  margin-left: 5px;
  font-size: 14px;
}
.monkeyContainer > div {
  background-color: #FAFAFA;
  border-radius: 15px;
  padding: 20px;
  img {
    width: 596px;
    height: 596px;
    object-fit: cover;
    max-width: 100%;
    box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 991px) {
    img {
      width: 100%;
      height: auto;
    }
  }
}
.btnCarouselShopNext,
.btnCarouselShopPrev {
  position: absolute;
  z-index: 1;
  border: 0;
  width: 40px;
  height: 40px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  background-color: transparent;
}
.btnCarouselShopNext {
  right: 5%;
}
.btnCarouselShopPrev {
  left: 5%;
  transform: rotate(180deg);
}
@media (max-width: 1399px) {
  .btnCarouselShopNext {
    right: 3%;
  }
  .btnCarouselShopPrev {
    left: 3%;
  }
}
@media (max-width: 1199px) {
  .btnCarouselShopNext {
    right: 7%;
  }
  .btnCarouselShopPrev {
    left: 7%;
  }
}
@media (max-width: 991px) {
  .btnCarouselShopPrev,
  .btnCarouselShopNext {
    top: -5%;
    bottom: 0;
    margin-bottom: auto;
    margin-top: auto;
  }
}

.monkeyCarouselDots {
  display: inline-block;
  width: 68px;
  height: 6px;
  margin: 0 5px;
  border-radius: 3px;
  background-color:#E9EAEB;
  cursor: pointer;
}
.monkeyCarouselDots.active {
  background-color: #3097AA;
}
.pill {
  position: absolute;
  top: 0px;
  right: 0;
  background: #1C1C1C;
  color: white;
  font-family: $secondaryFont;
  border-radius: 64px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-20px);
  z-index: 1;
  span {
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 50%;
    background: #F04438;
    margin-right: 7px;
  }
}
.pill.reserved {
  span {
    background-color: #F79009;
  }
}
.descriptionBox .box {
  border: 1px solid #E6E6E6;
  border-radius: 24px;
  padding: 24px 16px;
  margin-bottom: 16px;
  font-size: 16px;
  position: relative;

  .monkeyPrice {
    margin-bottom: 0;
    .priceText {
      font-size: 16px;
      font-weight: 400;
    }
    .priceNumber {
      font-size: 48px;
      line-height: 60px;
      font-weight: 400;
    }
  }
  .monkeyAttributes {
    border: 1px solid #D6D7DD;
    border-radius: 60px;
    padding:8px 12px;
    margin-right: 10px;
    margin-top: 16px;
  }
  .monkeyCharacter {
    margin-bottom: 0;
  }
  .textGray {
    color: #717680;
  }
  .showMore {
    color: black;
    font-weight: bold;
    text-decoration: none !important;
  }
}
.descriptionBox .box-sold {
  background-color: #E9EAEB;
  color: #D5D7DA;
}
.descriptionBox > div.noBorder {
  border:0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.descriptionBox button.shop {
  width: 100%;
  border-radius: 8px;
  background-color: black;
  color: white;
  font-size: 16px;
  font-family: $secondaryFont;
  font-weight: 700;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 10px;
  }
}
@media (min-width: 992px) {
  .monkeyContainer > div {
    width: calc(100% - 16px)
  }
  .monkeyHistory {
    .historyText {
      padding-right: 16px;
    }
    .hisotryImages {
      padding-left: 16px;
    }
  }
}
.addDisplay {
  .title {
    font-size: 48px;
    margin-bottom: 0;
  }
  .subtitle {
    font-size: 20px;
  }
  .titleInBox {
    font-size: 36px;
    margin-bottom: 0;
  }
  .descriptionInBox {
    font-size: 14px;
  }
  .badgeInBox {
    border-radius: 40px;
    background: black;
    color: white;
    font-weight: 400;
    padding: 4px 12px;
    font-size: 16px;
    font-family: $primaryFont;
  }
}
.btnsMonkeyActions {
  .btnArtplacer {
    height: 40px;
    border-radius: 8px;
    padding: 0 14px;
    border: 0;
    font-family: $primaryFont;
    font-size: 16px;
    color: #414651;
    border: 1px solid #D5D7DA;
    background-color: #fff;
    margin-left: 16px;
    display: flex;
    button {
      border: 0;
      background: white;
    }
  }
}
.btnsMonkeyActions {
  overflow-x: auto;
  white-space: nowrap;
}
.btnsMonkeyActions::-webkit-scrollbar {
  height: 1px;
}
.btnsMonkeyActions::-webkit-scrollbar-track {
  background: transparent;
}
.btnsMonkeyActions::-webkit-scrollbar-thumb {
  background: transparent;
}
@media (max-width:1199px) {
  .btnsMonkeyActions {
    justify-content: flex-start !important;
  }
}
.monkeyHistory {
  h2 {
    font-size: 64px;
  }
  p {
    color: #717680;
  }
  p.subtitle {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.45);
  }
}
.expandable-container {
  .title {
    font-size:24px;
    color: black;
    margin-bottom: 10px;
  }
  border-radius: 24px;
  border: 1px solid #E9EAEB;
  padding: 24px 16px;
  margin-bottom: 20px;

  button {
    font-family: $secondaryFont;
    font-size: 16px;
    border: 0;
    background: #fff;
    color: #000;
    font-weight: 700;
    padding: 3px 7px;
  }
  button.expanded {
    box-shadow: 0px 5px 5px rgba(0,0,0,.1);
    border-radius: 16px;
  }
}
.expandable-container.expanded {
  border-color: #4BB3C4;
}
.imgCarouselHistorical {
  width: 100%;
  max-width: 100%;
  height: 420px;
  object-fit: cover;
}