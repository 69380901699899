@import "../../styles/styles.scss";
@import "./homeStyles";

.Container {
  @include grid-container(repeat(12, 1fr), null);
  height: 100%;
  min-height: 777px;
  overflow: hidden;
  @include media-query($max-width-desktop-large) {
    min-height: 700px;
  }
  @include media-query($max-width-desktop) {
    min-height: 660px;
  }
  @include media-query($max-width-tablet) {
    min-height: 800px;
  }
  @include media-query($max-width-mobile) {
    min-height: 500px;
  }
}

.WrappClaimSpan {
  @include grid-container(repeat(12, 1fr), null);
  @include grid-start-end(1, 13, 1, 13);

  @include media-query($max-width-desktop-large) {
    @include grid-start-end(1, 13, 1, 13);
  }

  @include media-query($max-width-desktop) {
    @include grid-start-end(1, 13, 1, 13);
  }

  @include media-query($max-width-tablet) {
    @include grid-start-end(1, 13, 1, 13);
  }

  @include media-query($max-width-mobile) {
    @include grid-start-end(1, 13, 1, 13);
  }

  height: 100%;
  position: relative;
  @media (max-width: 768px) {
    align-items: center;
  }
}

.Emojis {
  position: relative;
  height: 100%;
  width: 100%;
  font-size: 70px;
  @include grid-start-end(1, 13, 1, 13);
  padding-left: 2rem;

  & span:nth-child(1) {
    display: grid !important;
    @include grid-start-end(6, 7, 4, 6);
  }
  & span:nth-child(2) {
    display: grid !important;
    @include grid-start-end(2, 3, 2, 4);
  }
  & span:nth-child(3) {
    display: grid !important;
    @include grid-start-end(11, 9, 12, 13);
    z-index: 3 !important;
  }
  & span:nth-child(4) {
    display: grid !important;
    @include grid-start-end(2, 4, 8, 10);
  }
  & span:nth-child(5) {
    display: grid !important;
    @include grid-start-end(5, 7, 11, 13);
    transform: translate(0, 0);
  }

  & .WrappText {
    display: grid;
    @include grid-start-end(1, 13, 1, 13);
    z-index: 1 !important;

    & span:nth-child(1) {
      @include grid-start-end(1, 13, 4, 12);
    }
  }

  .emoji {
    font-size: 6rem;
    @include media-query($max-width-tablet) {
      font-size: 4rem;
    }

    @include media-query($max-width-mobile) {
      font-size: 3rem;
    }
  }

  .EmojiParallax1 {
    transform: rotate(0deg);
  }
  .EmojiParallax2 {
    transform: rotate(3deg);
  }
  .EmojiParallax3 {
    transform: rotate(-10deg);
  }
  .EmojiParallax4 {
    transform: rotate(-5deg);
  }
  .EmojiParallax5 {
    transform: rotate(-3deg);
  }
}

.ClaimSpan {
  width: 100%;
  @include media-query($max-width-mobile) {
    margin-top: 1rem;
  }
  /* @include grid-start-end(1, 12, 1, 13); */
  & > span {
    font-family: $primaryFont;
    font-weight: bold;
    font-size: 145px;
    line-height: 150px;
    text-transform: uppercase;
    text-wrap: wrap;
    @include media-query($max-width-desktop-large) {
      font-size: 140.09px;
      line-height: calc(140.09px * 0.9);
    }

    @include media-query($max-width-desktop) {
      font-size: 120.09px;
      line-height: calc(120.09px * 0.9);
    }

    @include media-query($max-width-tablet) {
      font-size: 110px;
      line-height: calc(110px * 0.9);
    }

    @include media-query($max-width-mobile) {
      font-size: 70.09px;
      line-height: calc(70.09px * 0.9);
    }
  }

  .HoverLink1:hover {
    color: #eeadd7;
    cursor: pointer;
  }
  .HoverLink2:hover {
    color: #4bb3c4;
    cursor: pointer;
  }
  .HoverLink3:hover {
    color: #f3c020;
    cursor: pointer;
  }
}
