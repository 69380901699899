@import "styles.scss";

.modalCart {
  display: none !important;
  position:fixed;
  top:0;
  left:0;
  z-index:10;
  .cart {
    width: 409px;
    height: 100vh;
    overflow-y: auto;
    background: white;
    padding: 24px;
    transform: translateX(100%);
    transition: transform 0.5s ease;

    .title {
      font-family: $primaryFont;
      font-size: 24px;
      .badge {
        background-color: #DF365D;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        color: white;
        font-size: 10px;
        padding-top: 5px;
        display:flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
      }
    }

    .offerLayout {
      background-color: #D9F2F4;
      color: #264753;
      font-size: 12px;
      padding: 8px 16px;
      border-radius: 12px;
      border-bottom: 3px solid #4BB3C4;
      position: relative;
      width: calc(100% - 15px);
      p {
        font-family: $secondaryFont;
      }


      .gift {
        background-color: #3097AA;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto -15px auto 0;
      }
    }

    .itemsList {
      .item {
        img {
          width: 40px;
          height: 40px;
          border-radius: 4px;
        }
        .btnRemove {
          width: 36px;
          height: 36px;
          border-radius: 8px;
          border:1px solid #D5D7DA;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .fancyNumber {
          font-family: $secondaryFont;
          font-size: 14px;
        }
        .fancyPrice {
          font-family: $primaryFont;
          font-size: 16px;
        }
      }
    }

    .finePrintArt {
      border: 2px solid #E9EAEB;
      border-radius: 24px;
      padding: 24px;
      position: relative;
      .badge {
        position:absolute;
        top: 0;
        right: 24px;
        margin-top: -15px;
        background: #414651;
        color: white;
        border-radius: 40px;
        font-family: $secondaryFont;
        font-size: 14px;
        padding: 8px 12px;
      }
      .title {
        font-family: $secondaryFont;
        font-size: 20px;
      }
      .description {
        font-family: $secondaryFont;
        font-size: 12px;
        color: #717680;
      }
    }

    .discount {
      .percentage {
        width: 36px;
        height: 36px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items:center;
        background: rgba(0, 0, 0, 0.06)
      }
      .text {
        font-family: $secondaryFont;
        font-size: 14px;
        color: #717680;
        margin-left: 12px;
        span {
          color: #4BB3C4;
          font-weight: bold;
          cursor: pointer;
        }
      }
      input {
        width: 90%;
        margin: 0 auto;
      }
    }

    .priceContainer {
      .total {
        font-family: $secondaryFont;
        font-size: 24px;
      }
      .price {
        font-family: $primaryFont;
        text-align: right;
        font-size: 36px;
      }
    }
    .btnClose {
      border: 0;
      background-color: white;
    }
    .btnContinue {
      padding: 8px 14px;
      background-color: black;
      color: white;
      border-radius: 8px;
      font-family: $primaryFont;
      font-size: 16px;
      border: 0;
    }
    .btnPaypal,
    .btnMercadopago {
      color: #414651;
      background: white;
      border: 1px solid #D5D7DA;
    }
    .btnPaypal {
      img {
        width: 18px;
      }
    }
    .btnMercadopago {
      img {
        width: 25px;
      }
    }
    .btnShopping {
      padding: 8px 14px;
      background-color: white;
      color: black;
      border-radius: 8px;
      font-family: $primaryFont;
      font-size: 16px;
      border: 1px solid #D5D7DA;
    }
  }
}

.modalCart.fancyCheckout {
  display: inline !important;
  width: 409px !important;
  position: sticky;
  top: 0;
  height: auto !important;
  .cart {
    transform: translateX(0) !important;
    transform: translateY(0) !important;
    height: auto !important;
    .btnClose {
      display: none !important;
    }
  }
}
.modalCart.show {
  display: flex !important;
  background-color: transparent;
  transition: background-color 0.5s ease;
}
.modalCart.show.bg {
  background: rgba(0,0,0,.8);
}
.cart.show {
  transform: translateX(0);
}

@media (max-width: 991px) {
  .modalCart {
    .cart {
      width: 100%;
      height: calc(100% - 30px);
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      overflow-y: auto;
      transform: translateX(0%);
      transform: translateY(100%);
    }
    .cart.show {
      transform: translateY(0);
    }
  }
  .modalCart.show.bg {
    background-color: #62696F;
  }
}