@import "../../styles/styles.scss";

.artistPage {
    font-family: $secondaryFont;
    img {
        max-width: 100%;
    }
    .carouselContainer {
        width: 100%;
        height: 100vh;
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            background-image: url('../../assets/arrow-carousel.svg');
            width: 40px;
            height: 40px;
        }
        .carousel-control-prev-icon {
            transform: rotate(180deg);
        }
        .carouselItem {
            width: 100%;
            height: 100vh;
            background-size: cover;
            background-position: center;
            h1,
            h2 {
                font-family: $primaryFont;
                font-size: 105px;
                color: white;
                text-shadow: 0px 0px 10px black;
                text-align: center;
            }
            @media (max-width: 991px) {
                h1,
                h2 {
                    font-size: 40px;
                }
            }
        }
    }
    .biography {
        h3 {
            font-family: $primaryFont;
            font-size: 60px;
        }
        .subtitle {
            font-size: 30px;
        }
        .description {
            font-size: 24px;
        }
        button {
            background: transparent;
            border: 0;
            color: #535862;
            font-size: 16px;
            font-weight: bold;
        }
    }
    .photoWall {
        img {
            margin-top: 10px;
            width: calc(100% - 10px);

        }
    }
    .carouselBlack  {
        margin-top: -10px;
    }

    .accordionSite {

        .accordion-item {
            border: none !important;
            background: none !important;
        }

        .accordion-button {
            background: none !important;
            border: none !important;
            box-shadow: none !important;
            font-size: 120px;
            padding: 10px;
            text-align: left;
            font-family: $primaryFont;
        }
        @media (max-width: 991px) {
            .accordion-button {
                font-size: 40px;
            }
        }

        .accordion-button::after {
            display: none !important; /* Oculta el caret */
        }

        .accordion-body {
            background: none !important;
            border: none !important;
            padding: 10px;
        }
        .accordion-button:not(.collapsed) {
            background: none !important;
        }
        .accordion-button.btn-pintura:not(.collapsed),
        .accordion-button.btn-pintura:hover {
            color: #4BB3C4;
        }
        .accordion-button.btn-grafica:not(.collapsed),
        .accordion-button.btn-grafica:hover {
            color: #D6A857;
        }
        .accordion-button.btn-arquitectura:not(.collapsed),
        .accordion-button.btn-arquitectura:hover {
            color: #62957A;
        }
        .accordion-button.btn-mobiliario:not(.collapsed),
        .accordion-button.btn-mobiliario:hover {
            color: #EEADD8;
        }
        .accordion-button.btn-escultura:not(.collapsed),
        .accordion-button.btn-escultura:hover {
            color: #5D5D5C;
        }
        .accordion-button.btn-escenografia:not(.collapsed),
        .accordion-button.btn-escenografia:hover {
            color: #9B8FCF;
        }
        .accordion-collapse  {
            display: block !important;
            max-height: 0;
            transition: max-height 0.6s ease-out;
            overflow: hidden;

        }
        .accordion-collapse.show {
            max-height: 718px;
            transition: max-height 0.6s ease-in;
        }
        .carousel-control-prev {
            left: 0%;
            width: 6%;
        }
        .carousel-control-next {
            right: 17%;
            width: 6%;
        }
        @media (max-width: 991px) {
            .carousel-control-prev {
                width: 10%;
            }
            .carousel-control-next {
                right: 0%;
                width: 10%;
            }
            .carousel-control-prev-icon,
            .carousel-control-next-icon {
                display: block;
            }
        }
    }
}