@import "styles.scss";

#archive {
  .archive-wrapper {
    .hero {
      padding-top: calc($dimenXXXL * 2 + $dimenS);
      padding-bottom: calc($dimenXXL);
    }

    .archive-title {
      font-family: $primaryFont;
      color: $black;
      text-align: center;
      font-size: 54px;
      line-height: 64px; /* 118.519% */
      text-transform: uppercase;
      width: 70%;
      overflow: hidden;
      @media (max-width: 1400px) {
        width: 80%;
      }
      @media (max-width: 1200px) {
        width: 95%;
      }
      @media (max-width: 1000px) {
        width: 100%;
        font-size: 32px;
        line-height: 41px;
      }
    }
    .archive-description {
      color: #676767;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: $secondaryFont;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px; /* 140% */
      margin-top: $dimenM;
      width: 50%;
      @media (max-width: 1000px) {
        width: 80%;
      }
      @media (max-width: 768px) {
        width: 100%;
        font-size: 16px;
        line-height: 24px; /* 150% */
      }
    }
    .wrapper-images {
      columns: 4;
      column-gap: 0;
      margin-bottom: 72px;
      @media (max-width: 992px) {
        columns: 2;
        column-gap: $dimenXS;
        padding: 0 $dimenS;
      }
      @media (max-width: 380px) {
        columns: 1;
        column-gap: $dimenXS;
      }

      .box {
        width: 100%;
        overflow: hidden;
        position: relative;
        transition: 0.3s;

        @media (max-width: 992px) {
          padding-bottom: $dimenXS;
        }

        img {
          width: 100%;
          object-fit: cover;
          @media (max-width: 992px) {
            border-radius: 4px;
          }
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.8) 100%
          );
          opacity: 0;
          display: none;
          cursor: pointer;
          transition: 0.3s;
          @media (max-width: 992px) {
            display: none;
          }
        }
        &:hover .overlay {
          display: block;
          transition: 0.3s;
          opacity: 0.9;
          @media (max-width: 992px) {
            display: none;
          }
        }

        .view-detail {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: none;

          a {
            text-decoration: none;
          }

          .button-detail {
            display: flex;
            width: 140px;
            padding: 14px 10px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 1.5px solid $white;
            box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.24) inset,
              0px -1px 0px 0px rgba(3, 19, 53, 0.03) inset,
              0px 1px 2px 0px rgba(17, 24, 39, 0.05);
            background-color: transparent;
            color: $white;
            font-feature-settings: "clig" off, "liga" off;
            font-family: $secondaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
            @media (max-width: 992px) {
              display: none;
            }
          }
        }
        &:hover .view-detail {
          display: block;
          transition: 0.3s;
        }

        .icon-arrow {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.4);
          border-radius: 55%;
          position: absolute;
          bottom: 1.5rem;
          right: 1rem;
          svg {
            width: 13px;
          }
        }
      }
    }
  }
}

#archive-detail {
  .archive-detail-wrapper {
    width: 100vw;
    .hero-section {
      padding-top: calc($dimenXXXL * 2 + $dimenS);
      padding-bottom: calc($dimenXXL);
    }

    .detail-info {
      display: flex;
      justify-content: center;
      gap: $dimenXL;

      @media (max-width: 992px) {
        flex-direction: column;
      }

      img {
        border-radius: 6px;
      }

      .detail-info-text {
        display: flex;
        flex-direction: column;
      }
      .detail-info-title {
        color: $black;
        font-feature-settings: "clig" off, "liga" off;
        font-family: $primaryFont;
        font-size: 58px;
        font-style: normal;
        font-weight: 400;
        line-height: 84px; /* 144.828% */
        @media (max-width: 992px) {
          font-size: 30px;
          line-height: 34px; /* 113.333% */
        }
      }
      .detail-info-description {
        color: $black;
        font-family: $secondaryFont;
        font-size: 18px;
        font-style: normal;
        font-weight: lighter;
        line-height: 30px; /* 166.667% */
        @media (max-width: 992px) {
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px; /* 150% */
        }
      }
    }

    .mySwiper-items {
      position: relative;
      overflow: hidden;
      margin-top: $dimenL;
      min-height: 180px;
      .swiper-wrapper {
        padding-bottom: 0;
        .swiper-slide {
          border-radius: 10px;
        }
      }
      img {
        height: auto;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        @media (max-width: 992px) {
          height: auto;
          width: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }

    .monas-carousel {
      padding-top: $dimenXXXL;
      padding-bottom: $dimenXXXL;

      .monas-carousel-title {
        color: $black;
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-family: $secondaryFont;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px; /* 100% */
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 48px;
        @media (max-width: 992px) {
          justify-content: flex-start;
          font-size: 30px;
          font-weight: 600;
          line-height: 38px; /* 126.667% */
        }
      }
      @keyframes slide {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100%);
        }
      }

      .monas-badge {
        padding: $dimenXL $dimenM;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .bg-banner {
      background-color: #2d7fdf;

      .archive-banner {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .banner-content {
          display: flex;
          flex-direction: column;
          a {
            text-decoration: none;
          }
        }
        .banner-title {
          color: $white;
          font-feature-settings: "clig" off, "liga" off;
          font-family: $secondaryFont;
          font-size: 68px;
          font-style: normal;
          font-weight: lighter;
          line-height: 68px; /* 100% */
          white-space: nowrap;

          span {
            color: $white;
            font-feature-settings: "clig" off, "liga" off;
            font-family: $secondaryFont;
            font-size: 68px;
            font-style: normal;
            font-weight: 700;
            line-height: 68px;
          }
        }

        .button-banner {
          display: flex;
          width: 180px;
          height: 36px;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          flex-shrink: 0;
          background-color: $black;
          border-radius: 6px;
          box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
          color: $white;
        }
      }
    }
  }
}
