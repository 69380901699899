@import "styles.scss";

.modal-background {
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: $black, $alpha: 0.6);
  margin: 0 !important;
}

.modal-term-container {
  margin: 0px;
  padding: $dimenL $dimenL $dimenL $dimenL;
  width: 702px;
  background: $white;
  border-radius: 12px;
  border: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 555px;
  overflow-y: scroll;
}

.modal-footer-terms {
  position: sticky;
  bottom: -24px;
  display: flex;
  flex-direction: column;
  gap: $dimenS;
  background-color: #fff;
  padding: $dimenS;
}

.modal-term-container h3 {
  margin: 0px 0px $dimenXXXS 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.modal-term-container p {
  margin: 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.modal-term-container .modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 0.4px solid #e0e0e0;
  margin-bottom: $dimenL;
  button:hover {
    background-color: transparent;
  }
  .modal-title {
    color: #000;
    font-family: $font11;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 115% */
    @media (max-width: $mediaLG) {
      margin-bottom: 1rem;
      width: 90%;
      text-align: left;
    }
  }
  .modal-subtitle {
    color: #000;
    font-family: $font11;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 23px; /* 164.286% */
    margin-bottom: $dimenS;
  }
  .modal-close-icon {
    position: absolute;
    right: $dimenL;
    top: $dimenL;
    svg {
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
    }
  }
}
@media (max-width: $mediaLG) {
  .modal-term-container {
    margin: 0px;
    padding: $dimenXL $dimenS $dimenS $dimenS;
    min-width: 280px;
    max-width: 90%;
    width: 90%;
    border-radius: 10px;
  }
}

.modal-historysales-container {
  margin: 0px;
  padding: $dimenL $dimenL;
  width: 1000px;
  max-width: 90vw;
  background: $white;
  border-radius: 12px;
  border: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;

  #table {
    display: grid;
    grid-template-columns: max-content 1fr;
    padding: 20px 0px;

    .seller-header,
    .client-header {
      background-color: #e0e0e0;
      padding-top: 1px;
      padding-bottom: 1px;
      display: flex;
      align-items: center;

      div {
        padding: 20px 0px;
        width: 100%;
      }
    }

    .seller-header {
      margin-left: -24px;

      @media (max-width: 992px) {
        margin-left: -16px;
      }

      div {
        padding: 24px;
        @media (max-width: 992px) {
          padding: 24px;
        }
        background-color: #fff;
      }
    }

    .client-header {
      margin-right: -24px;

      @media (max-width: 992px) {
        margin-right: -16px;
      }

      div {
        padding: 24px;
        @media (max-width: 992px) {
          padding: 16px;
        }
      }
    }

    .seller-col,
    .client-col {
      padding: 20px;
      border-bottom: 2px solid #e0e0e0;
    }

    .seller-col {
      display: flex;
      align-items: center;
    }

    .client-col {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 24px;
      gap: 20px;

      @media (max-width: 992px) {
        padding-left: 16px;
      }

      .primary-data,
      .secondary-data {
        width: 100%;
        display: grid;
        gap: 24px;
        justify-content: space-between;
        align-items: center;
        grid-template-columns: repeat(4, 1fr);

        @media (max-width: 992px) {
          gap: 16px;
        }

        .artworks {
          display: grid;
          grid-template-columns: repeat(3, 60px);
          gap: 4px;

          .fancymona {
            display: flex;
            flex-direction: column;

            img {
              width: 60px;
              height: 60px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              border-radius: 5px;
            }
          }
        }
      }

      .secondary-data {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

.modal-historysales-container h3 {
  margin: 0px 0px $dimenXXXS 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.modal-historysales-container p {
  margin: 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.modal-historysales-container .modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 0;
  margin-bottom: 0;
  button:hover {
    background-color: transparent;
  }
  .modal-title {
    color: #000;
    font-family: $font11;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 115% */
  }
  .modal-subtitle {
    color: #000;
    font-family: $font11;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 23px; /* 164.286% */
    margin-bottom: $dimenS;
  }
  .modal-close-icon {
    position: absolute;
    right: $dimenL;
    top: $dimenL;
    svg {
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
    }
  }
}
@media (max-width: $mediaLG) {
  .modal-historysales-container {
    margin: 0px;
    padding: $dimenXL $dimenS;
    min-width: 280px;
    max-width: 90%;
    width: 90%;
    border-radius: 10px;
  }
}

.modal-favs-container {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  width: 615px;
  max-width: 90%;
  position: relative;

  .modal-title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .modal-text {
    font-size: 16px;
    color: #666;
    margin-bottom: 40px;
  }

  .favorites-grid {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    padding: 0 50px;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    gap: 16px;

    .btn {
      padding: 10px 15px;
      border-radius: 5px;
      cursor: pointer;
    }

    .btn-light {
      background: white;
      border: 1px solid #ddd;
    }

    .btn-dark {
      background: black;
      color: white;
    }
  }

  .no-show {
    font-size: 16px;
    color: #666;
    text-decoration: underline;
    cursor: pointer;
  }

  .Close-Container {
    display: flex;
    justify-content: flex-end;
  }
}
.login-modal {
  overflow-y: hidden;
}