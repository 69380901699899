@import "./styles.scss";

.successPayment {
  h1 {
    font-family: $secondaryFont;
    font-size: 24px;
    font-weight: 700;
  }
  p {
    font-family: $secondaryFont;
    font-size: 16px;
  }
  button {
    border: 0;
    padding: 10px 0px;
    border-radius: 8px;
    font-family: $primaryFont;
    font-size: 16px;
    width: 278px;
  }
  button.black {
    background-color: black;
    color: white;
  }
  button.white {
    background-color: white;
    color: #414651;
    border: 1px solid #D5D7DA;
  }
}
